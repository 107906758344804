import React from 'react';
import axios, { AxiosError } from 'axios';
import { Section } from '../models/sectionModel';
import { API_RESPONSE } from './responses';

const API_URL = process.env.REACT_APP_API_URL;

export const editSection = async (
   sections: Section[],
   name: string,
   color: string,
   setData: React.Dispatch<any>,
   id: number
) => {
   try {
      const payload = {
         id: id,
         name: name,
         color: color,
      };

      const query = await axios.post(
         `${API_URL}/section/edit`,
         JSON.stringify(payload),
         {
            headers: {
               'Content-Type': 'application/json',
               Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
            },
         }
      );

      if (query.data.status === 200 || query.data.status === 201) {
         // Remove the old section data
         const withoutOldSection = sections.filter(
            (section) => section.id !== id
         );

         const data = query.data.section;
         setData([data, ...withoutOldSection]);

         return {
            type: API_RESPONSE.SUCCESS,
            data: query.data.message,
         };
      } else {
         return {
            type: API_RESPONSE.API_ERROR,
            data: query.data.errors,
         };
      }
   } catch (e) {
      const error = e as AxiosError;
      return {
         type: API_RESPONSE.GENERIC_ERROR,
         data: error,
      };
   }
};
