import axios, { AxiosError } from 'axios';
import { SubSection } from '../models/sectionModel';
import { Dispatch, SetStateAction } from 'react';
import { API_RESPONSE } from './responses';

const API_URL = process.env.REACT_APP_API_URL;

export interface PostSectionError {
   name: string[];
   color: string[];
}

export const postSubsection = async (
   sectionName: string,
   categoryId: number | undefined,
   sectionId: number,
   setData: Dispatch<SetStateAction<SubSection[]>>
) => {
   const payload = {
      name: sectionName,
      category_id: categoryId,
      section_id: sectionId,
   };

   try {
      const query = await axios.post(
         `${API_URL}/subsection/create`,
         JSON.stringify(payload),
         {
            headers: {
               'Content-Type': 'application/json',
               Authorization: `Bearer ${localStorage.getItem('auth_token')}`,
            },
         }
      );

      if (query.data.status === 200 || query.data.status === 201) {
         setData((prev) => [...prev, query.data.subsection]);
         return {
            type: API_RESPONSE.SUCCESS,
            data: query.data.message,
         };
      } else {
         return {
            type: API_RESPONSE.API_ERROR,
            data: query.data.errors,
         };
      }
   } catch (e) {
      const error = e as AxiosError;
      console.log(e);
      return {
         type: API_RESPONSE.GENERIC_ERROR,
         data: error.message,
      };
   }
};
